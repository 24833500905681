<template>
  <div class="church-members-friends template-2">
    <page-header class="mx-lg">
      <h1>{{ translations.tcChurchMembersOrFriends }}</h1>
      <BackJump @reload_host="pageLoad()" :obj="backJumpObj" :vis="true" :i18n="translations.components"></BackJump>
    </page-header>
    <page-body class="mx-lg">
      <data-table
        :fields="member_fields"
        :items="church_members"
        :addItems="{ display: false }"
        :hiddenItems="hiddenItems"
        :includeAddButton="includeAddButton"
        @addMember="addMember"
        :title="translations.tcMembers"
        :toggle="true"
        :pdfUrl="churchMembersPDFUrl"
        :csvUrl="churchMembersCSVUrl"
        :i18n="translations.components"
      ></data-table>
      <data-table
        :fields="friend_fields"
        :items="church_friends"
        :addItems="{ display: false }"
        :hiddenItems="hiddenItems"
        :title="translations.tcFriends"
        :toggle="true"
        :pdfUrl="churchFriendsPDFUrl"
        :csvUrl="churchFriendsCSVUrl"
        :i18n="translations.components"
        :searchTerm="`churchMembersTerm`"
      ></data-table>
    </page-body>
    <page-footer class="mx-lg">
      <BackJump :obj="backJumpObj" :vis="false" :i18n="translations.components"></BackJump>
    </page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BackJump from '@/components/back-jump/BackJump.vue'
import DataTable from '@/components/DataTable.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-members-friends',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      member_fields: [],
      friend_fields: [],
      includeAddButton: false,
      hiddenItems: {
        display: false,
      },
      addItems: {},
      backJumpObj: {
        link: '#',
        location: '',
      },
      secured_church_member_add_controls: {
        add_church_member_button: 'e6efadee-8725-4747-85d9-dba5a260cefa',
      },
    }
  },
  methods: {
    ...mapActions({
      loadChurchDetail: 'churchMinistry/loadChurchDetailSelect',
      setChurchMembersAndFriends: 'churchMinistry/setChurchMembersAndFriends',
      setLoadingStatus: 'menu/setLoadingStatus',
      getChurchFriendsPDFUrl: 'churchMinistry/getChurchFriendsPDFUrl',
      getChurchFriendsCSVUrl: 'churchMinistry/getChurchFriendsCSVUrl',
      getChurchMembersPDFUrl: 'churchMinistry/getChurchMembersPDFUrl',
      getChurchMembersCSVUrl: 'churchMinistry/getChurchMembersCSVUrl',
    }),
    addMember() {
      // select a camp member and update the church membership relationship
      // on their profile and display them as members in the church.
    },
    async pageLoad() {
      this.includeAddButton = this.iCanSee(this.secured_church_member_add_controls.add_church_member_button)
      await Promise.all([
        await this.setLoadingStatus(true),
        await this.loadChurchDetail(),
        await this.setChurchMembersAndFriends(this.userSelectedChurchKey),
        await this.getChurchFriendsPDFUrl(this.userSelectedChurchKey),
        await this.getChurchFriendsCSVUrl(this.userSelectedChurchKey),
        await this.getChurchMembersPDFUrl(this.userSelectedChurchKey),
        await this.getChurchMembersCSVUrl(this.userSelectedChurchKey),
      ]).then(() => {
        this.backJumpObj.location = this.churchDetail.church_name
        this.backJumpObj.link = '/programs/cm/church-profile'
        this.setLoadingStatus(false)
      })
    },
    loadTranslations() {
      this.member_fields = [
        { key: 'member_name', label: this.translations.tcMemberName, sortable: true },
        { key: 'camp_name', label: this.translations.tcCampName, sortable: true },
      ]
      this.friend_fields = [
        { key: 'friend_name', label: this.translations.tcFriendName, sortable: true },
        { key: 'camp_name', label: this.translations.tcCampName, sortable: true },
      ]
      this.addItems = {
        display: true,
        text: this.translations.tcAddChurchMember,
        action: 'addMember',
      }
    },
  },
  async created() {
    await Promise.all([
      this.getViewTranslations(),
      this.getComponentTranslations('back-jump', 'quick-jump', 'data-table'),
      this.pageLoad(),
    ]).then((results) => {
      this.loadTranslations()
      const componentTranslations = results[1]
      this.$set(this.translations, 'components', componentTranslations)
    })
  },
  computed: {
    ...mapGetters({
      church_friends: 'churchMinistry/church_friends',
      church_members: 'churchMinistry/church_members',
      churchDetail: 'churchMinistry/churchDetailSelect',
      churchFriendsPDFUrl: 'churchMinistry/churchFriendsPDFUrl',
      churchFriendsCSVUrl: 'churchMinistry/churchFriendsCSVUrl',
      churchMembersPDFUrl: 'churchMinistry/churchMembersPDFUrl',
      churchMembersCSVUrl: 'churchMinistry/churchMembersCSVUrl',
      iCanSee: 'user/iCanSee',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  components: {
    BackJump: BackJump,
    pageHeader: pageHeader,
    pageBody: pageBody,
    pageFooter: pageFooter,
    DataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
.church-members-friends {
  header h1 {
    padding-bottom: 80px;
    @include breakpoint(sm) {
      padding-bottom: 0;
    }
  }
  .list.container {
    margin-bottom: 40px;
    padding-left: 0;
    padding-right: 0;
    #results {
      margin-top: 0 !important;
      th {
        width: auto !important;
      }
    }
  }
}
</style>
